<template>
  <v-flex >
    <div class="headline font-weight-black">Jualan Jadi Gampang</div>
    <div class="d-flex align-start mt-4">
      Lorem ipsum dolor sit amet, consectetur adipiscing elit. Porttitor ut sit vulputate etiam elementum. Ut sed venenatis pharetra dui dolor massa. Sed rutrum ultrices id amet. Ac morbi facilisis viverra leo amet, fringilla.
    </div>
    <div class="mt-4">
      <v-btn depressed color="primary">Mulai Coba</v-btn>
      <v-btn outlined color="primary" class="ml-2">Ikuti Demo</v-btn>
    </div>
  </v-flex>

</template>
<script>
export default {
  name: "AppFooter"
};
</script>

<style scoped>
</style>
