export default class FPromotionRulesdValidProducts {
  constructor(
      id,
      noUrut,
      fpromotionRuleshBean,
      validMaterialBean,
      validMaterialGroup3Bean,
      validMaterialGroup3Accumulation,
      validMaterialGroup2Bean,
      validMaterialGroup2Accumulation,
      validMaterialSalesBrandBean,
      validSalesBrandAccumulation,
      validVendorBean,
      validVendorAccumulation
  ) {
    this.id = id;
    this.noUrut = noUrut;
    this.fpromotionRuleshBean = fpromotionRuleshBean;
    this.validMaterialBean = validMaterialBean;
    this.validMaterialGroup3Bean = validMaterialGroup3Bean;
    this.validMaterialGroup3Accumulation = validMaterialGroup3Accumulation;
    this.validMaterialGroup2Bean = validMaterialGroup2Bean;
    this.validMaterialGroup2Accumulation = validMaterialGroup2Accumulation;
    this.validMaterialSalesBrandBean = validMaterialSalesBrandBean;
    this.validSalesBrandAccumulation = validSalesBrandAccumulation;
    this.validVendorBean = validVendorBean;
    this.validVendorAccumulation = validVendorAccumulation;
  }
}
