<template>
  <section
      id="testimoni"
      class="grey lighten-3"
  >
    <div class="py-4"></div>

    <v-container class="text-center">
      <h2 class="display-2 font-weight-bold mb-10" v-if="false">Testimoni</h2>

      <v-row>
        <v-img
            :src="logopanjang"
            width="300"
            height="300"
            contain
        ></v-img>
      </v-row>

      <v-row v-if="true">
        <v-col
            v-for="({ icon, title, text }, i) in features"
            :key="i"
            cols="12"
            md="4"
        >
          <v-card
              class="py-12 px-4 rounded-lg"
              color="grey lighten-5"
              flat
              elevation="14"
          >
            <v-theme-provider dark>
              <div>
                <v-avatar
                    color="primary"
                    size="88"
                >
<!--                  <v-icon-->
<!--                      large-->
<!--                      v-text="icon"-->
<!--                  ></v-icon>-->

                  <v-img src="https://cdn.vuetifyjs.com/images/john.png"></v-img>
                </v-avatar>
              </div>
            </v-theme-provider>

            <v-card-title
                class="justify-center font-weight-black text-uppercase"
                v-text="title"
            ></v-card-title>

            <v-card-text
                class="subtitle-1"
                v-text="text"
            >
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>

    <div class="py-4"></div>
  </section>
</template>

<script>
export default {
  name: "Testimoni",
  data() {
    return {
      logopanjang: require('@/assets/logo-panjang.png'),
      features: [
        {
          icon: 'mdi-account-group-outline',
          title: 'Panji Winson',
          text: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Iusto cupiditate sint possimus quidem atque harum excepturi nemo velit tempora! Enim inventore fuga, qui ipsum eveniet facilis obcaecati corrupti asperiores nam',
        },
        {
          icon: 'mdi-update',
          title: 'Rina Putri',
          text: 'Sed ut elementum justo. Suspendisse non justo enim. Vestibulum cursus mauris dui, a luctus ex blandit. Lorem ipsum dolor sit amet consectetur adipisicing elit. qui ipsum eveniet facilis obcaecati corrupti consectetur adipisicing elit.',
        },
        {
          icon: 'mdi-shield-outline',
          title: 'M. Aliadit',
          text: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Iusto cupiditate sint possimus quidem atque harum excepturi nemo velit tempora! Enim inventore fuga, qui ipsum eveniet facilis obcaecati corrupti asperiores nam',
        },
      ],
    }
  }
}
</script>

<style scoped>

</style>