export default class FPromotionRulesh {
  constructor(
      id,
      sourceId,
      kode1,
      kode2,
      description,
      avatarImage,
      fdivisionBean,
      calcMethod,
      validPeriodDateFrom,
      validPeriodDateTo,
      sharedToCompany,
      statusActive,
      accAccountBean,
      accAccount_CreditBean,
      claimPabrik,
      maxTargetValue,
      maxTargetQty,
      totalValueApplied,
      totalQtyApplied,
      amountPayRp,
      validProductsAccumulation,
      promoDiscMethod,
      discMinQtyOrValue,
      discMaxQtyOrValue,
      discKelipatan,
      discCashOnly,
      forDiscQtyOrValueLev1,
      disc1GetLev1,
      disc1GetLev1_Value,
      disc2GetLev1,
      disc3GetLev1,
      disc1PlusGetLev1,
      disc2PlusGetLev1,
      forDiscQtyOrValueLev2,
      disc1GetLev2,
      disc1GetLev2_Value,
      disc2GetLev2,
      disc3GetLev2,
      disc1PlusGetLev2,
      disc2PlusGetLev2,
      forDiscQtyOrValueLev3,
      disc1GetLev3,
      disc1GetLev3_Value,
      disc2GetLev3,
      disc3GetLev3,
      disc1PlusGetLev3,
      disc2PlusGetLev3,
      forDiscQtyOrValueLev4,
      disc1GetLev4,
      disc1GetLev4_Value,
      disc2GetLev4,
      disc3GetLev4,
      disc1PlusGetLev4,
      disc2PlusGetLev4,
      forDiscQtyOrValueLev5,
      disc1GetLev5,
      disc1GetLev5_Value,
      disc2GetLev5,
      disc3GetLev5,
      disc1PlusGetLev5,
      disc2PlusGetLev5,
      promoFg1Method,
      fg1MinQtyOrValue,
      fg1Kelipatan,
      freeGood1MaterialBean,
      fg1HargaJualNol,
      fg1PricePcs,
      forFg1QtyOrValueLev1,
      fg1QtyGetLev1,
      forFg1QtyOrValueLev2,
      fg1QtyGetLev2,
      forFg1QtyOrValueLev3,
      fg1QtyGetLev3,
      forFg1QtyOrValueLev4,
      fg1QtyGetLev4,
      forFg1QtyOrValueLev5,
      fg1QtyGetLev5,
      promoFg2Method,
      fg2MinQtyOrValue,
      fg2Kelipatan,
      freeGood2MaterialBean,
      fg2HargaJualNol,
      fg2PricePcs,
      forFg2QtyOrValueLev1,
      fg2QtyGetLev1,
      forFg2QtyOrValueLev2,
      fg2QtyGetLev2,
      forFg2QtyOrValueLev3,
      fg2QtyGetLev3,
      forFg2QtyOrValueLev4,
      fg2QtyGetLev4,
      forFg2QtyOrValueLev5,
      fg2QtyGetLev5,
      cashBackValue1,
      cashBackGet1,
      cashBackValue2,
      cashBackGet2,
      cashBackValue3,
      cashBackGet3,
      cashBackValue4,
      cashBackGet4,
      discNotaCashOnly,
      forDiscNotaValueLev1,
      disc1NotaGetLev1,
      cashBackNotaGetLev1,
      forDiscNotaValueLev2,
      disc1NotaGetLev2,
      cashBackNotaGetLev2,
      forDiscNotaValueLev3,
      disc1NotaGetLev3,
      cashBackNotaGetLev3,
      forDiscNotaValueLev4,
      disc1NotaGetLev4,
      cashBackNotaGetLev4,
      forDiscNotaValueLev5,
      disc1NotaGetLev5,
      cashBackNotaGetLev5,
      created,
      modified,
      modifiedBy
  ) {
    this.id = id;
    this.sourceId = sourceId;
    this.kode1 = kode1;
    this.kode2 = kode2;
    this.description = description;
    this.avatarImage = avatarImage;
    this.fdivisionBean = fdivisionBean;
    this.calcMethod = calcMethod;
    this.validPeriodDateFrom = validPeriodDateFrom;
    this.validPeriodDateTo = validPeriodDateTo;
    this.sharedToCompany = sharedToCompany;
    this.statusActive = statusActive;
    this.accAccountBean = accAccountBean;
    this.accAccount_CreditBean = accAccount_CreditBean;
    this.claimPabrik = claimPabrik;
    this.maxTargetValue = maxTargetValue;
    this.maxTargetQty = maxTargetQty;
    this.totalValueApplied = totalValueApplied;
    this.totalQtyApplied = totalQtyApplied;
    this.amountPayRp = amountPayRp;
    this.validProductsAccumulation = validProductsAccumulation;
    this.promoDiscMethod = promoDiscMethod;
    this.discMinQtyOrValue = discMinQtyOrValue;
    this.discMaxQtyOrValue = discMaxQtyOrValue;
    this.discKelipatan = discKelipatan;
    this.discCashOnly = discCashOnly;
    this.forDiscQtyOrValueLev1 = forDiscQtyOrValueLev1;
    this.disc1GetLev1 = disc1GetLev1;
    this.disc1GetLev1_Value = disc1GetLev1_Value;
    this.disc2GetLev1 = disc2GetLev1;
    this.disc3GetLev1 = disc3GetLev1;
    this.disc1PlusGetLev1 = disc1PlusGetLev1;
    this.disc2PlusGetLev1 = disc2PlusGetLev1;
    this.forDiscQtyOrValueLev2 = forDiscQtyOrValueLev2;
    this.disc1GetLev2 = disc1GetLev2;
    this.disc1GetLev2_Value = disc1GetLev2_Value;
    this.disc2GetLev2 = disc2GetLev2;
    this.disc3GetLev2 = disc3GetLev2;
    this.disc1PlusGetLev2 = disc1PlusGetLev2;
    this.disc2PlusGetLev2 = disc2PlusGetLev2;
    this.forDiscQtyOrValueLev3 = forDiscQtyOrValueLev3;
    this.disc1GetLev3 = disc1GetLev3;
    this.disc1GetLev3_Value = disc1GetLev3_Value;
    this.disc2GetLev3 = disc2GetLev3;
    this.disc3GetLev3 = disc3GetLev3;
    this.disc1PlusGetLev3 = disc1PlusGetLev3;
    this.disc2PlusGetLev3 = disc2PlusGetLev3;
    this.forDiscQtyOrValueLev4 = forDiscQtyOrValueLev4;
    this.disc1GetLev4 = disc1GetLev4;
    this.disc1GetLev4_Value = disc1GetLev4_Value;
    this.disc2GetLev4 = disc2GetLev4;
    this.disc3GetLev4 = disc3GetLev4;
    this.disc1PlusGetLev4 = disc1PlusGetLev4;
    this.disc2PlusGetLev4 = disc2PlusGetLev4;
    this.forDiscQtyOrValueLev5 = forDiscQtyOrValueLev5;
    this.disc1GetLev5 = disc1GetLev5;
    this.disc1GetLev5_Value = disc1GetLev5_Value;
    this.disc2GetLev5 = disc2GetLev5;
    this.disc3GetLev5 = disc3GetLev5;
    this.disc1PlusGetLev5 = disc1PlusGetLev5;
    this.disc2PlusGetLev5 = disc2PlusGetLev5;
    this.promoFg1Method = promoFg1Method;
    this.fg1MinQtyOrValue = fg1MinQtyOrValue;
    this.fg1Kelipatan = fg1Kelipatan;
    this.freeGood1MaterialBean = freeGood1MaterialBean;
    this.fg1HargaJualNol = fg1HargaJualNol;
    this.fg1PricePcs = fg1PricePcs;
    this.forFg1QtyOrValueLev1 = forFg1QtyOrValueLev1;
    this.fg1QtyGetLev1 = fg1QtyGetLev1;
    this.forFg1QtyOrValueLev2 = forFg1QtyOrValueLev2;
    this.fg1QtyGetLev2 = fg1QtyGetLev2;
    this.forFg1QtyOrValueLev3 = forFg1QtyOrValueLev3;
    this.fg1QtyGetLev3 = fg1QtyGetLev3;
    this.forFg1QtyOrValueLev4 = forFg1QtyOrValueLev4;
    this.fg1QtyGetLev4 = fg1QtyGetLev4;
    this.forFg1QtyOrValueLev5 = forFg1QtyOrValueLev5;
    this.fg1QtyGetLev5 = fg1QtyGetLev5;
    this.promoFg2Method = promoFg2Method;
    this.fg2MinQtyOrValue = fg2MinQtyOrValue;
    this.fg2Kelipatan = fg2Kelipatan;
    this.freeGood2MaterialBean = freeGood2MaterialBean;
    this.fg2HargaJualNol = fg2HargaJualNol;
    this.fg2PricePcs = fg2PricePcs;
    this.forFg2QtyOrValueLev1 = forFg2QtyOrValueLev1;
    this.fg2QtyGetLev1 = fg2QtyGetLev1;
    this.forFg2QtyOrValueLev2 = forFg2QtyOrValueLev2;
    this.fg2QtyGetLev2 = fg2QtyGetLev2;
    this.forFg2QtyOrValueLev3 = forFg2QtyOrValueLev3;
    this.fg2QtyGetLev3 = fg2QtyGetLev3;
    this.forFg2QtyOrValueLev4 = forFg2QtyOrValueLev4;
    this.fg2QtyGetLev4 = fg2QtyGetLev4;
    this.forFg2QtyOrValueLev5 = forFg2QtyOrValueLev5;
    this.fg2QtyGetLev5 = fg2QtyGetLev5;
    this.cashBackValue1 = cashBackValue1;
    this.cashBackGet1 = cashBackGet1;
    this.cashBackValue2 = cashBackValue2;
    this.cashBackGet2 = cashBackGet2;
    this.cashBackValue3 = cashBackValue3;
    this.cashBackGet3 = cashBackGet3;
    this.cashBackValue4 = cashBackValue4;
    this.cashBackGet4 = cashBackGet4;
    this.discNotaCashOnly = discNotaCashOnly;
    this.forDiscNotaValueLev1 = forDiscNotaValueLev1;
    this.disc1NotaGetLev1 = disc1NotaGetLev1;
    this.cashBackNotaGetLev1 = cashBackNotaGetLev1;
    this.forDiscNotaValueLev2 = forDiscNotaValueLev2;
    this.disc1NotaGetLev2 = disc1NotaGetLev2;
    this.cashBackNotaGetLev2 = cashBackNotaGetLev2;
    this.forDiscNotaValueLev3 = forDiscNotaValueLev3;
    this.disc1NotaGetLev3 = disc1NotaGetLev3;
    this.cashBackNotaGetLev3 = cashBackNotaGetLev3;
    this.forDiscNotaValueLev4 = forDiscNotaValueLev4;
    this.disc1NotaGetLev4 = disc1NotaGetLev4;
    this.cashBackNotaGetLev4 = cashBackNotaGetLev4;
    this.forDiscNotaValueLev5 = forDiscNotaValueLev5;
    this.disc1NotaGetLev5 = disc1NotaGetLev5;
    this.cashBackNotaGetLev5 = cashBackNotaGetLev5;
    this.created = created;
    this.modified = modified;
    this.modifiedBy = modifiedBy;
  }
}
