export default class FPromotionRulesdValidCusts {
  constructor(
      id,
      noUrut,
      fpromotionRuleshBean,
      validCustomerBean,
      validAreaBean,
      validRegionBean,
      validCustomerGroupBean,
      validDistributionChannelBean
  ) {
    this.id = id;
    this.noUrut = noUrut;
    this.fpromotionRuleshBean = fpromotionRuleshBean;
    this.validCustomerBean = validCustomerBean;
    this.validAreaBean = validAreaBean;
    this.validRegionBean = validRegionBean;
    this.validCustomerGroupBean = validCustomerGroupBean;
    this.validDistributionChannelBean = validDistributionChannelBean;
  }
}