import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import store from '../store/index'

Vue.use(VueRouter)

const routes = [{
        path: '/',
        name: 'Home',
        component: Home
    },
    {
        path: '/home',
        name: 'home',
        component: Home
    },
    {
        path: '/dashboard-utama',
        name: 'DashboardUtama',
        component: () =>
            import('../views/Dashboard')
    },
    {
        path: '/login',
        name: 'Login',
        component: () =>
            import ('../views/Login.vue')
    },
    {
        path: '/register',
        name: 'Register',
        component: () =>
            import ('../views/RegisterView.vue')
    },
    {
        path: '/change-profile',
        name: 'ChangeProfile',
        component: () =>
            import ('../views/admin/ChangeProfile'),
    },
    {
        path: '/profile',
        name: 'Profile',
        component: () =>
            import ('../views/ProfileView.vue')
    },
    {
        path: '/admin-sistem',
        name: 'AdminSistem',
        component: () =>
            import ('../views/admin/SettingAndUser.vue')
    },
    {
        path: '/admin-struktur-organisasi',
        name: 'StrukturOrganisasi',
        component: () =>
            import ('../views/admin/StrukturOrganisasi.vue')
    },

    {
        path: '/customer',
        name: 'Customer',
        // lazy-loaded
        component: () => import('../views/mitra/Customer')
    },
    {
        path: '/cust-category',
        name: 'CustCateg',
        // lazy-loaded
        component: () => import('../views/mitra/CustCateg')
    },
    {
        path: '/cust-region',
        name: 'CustRegion',
        // lazy-loaded
        component: () => import('../views/mitra/CustRegion.vue')
    },
    {
        path: '/supplier',
        name: 'Supplier',
        // lazy-loaded
        component: () => import('../views/mitra/Vendor')
    },
    {
        path: '/expedisi',
        name: 'Expedisi',
        // lazy-loaded
        component: () => import('../views/mitra/Expedisi')
    },
    {
        path: '/mp-store',
        name: 'Store',
        // lazy-loaded
        component: () => import('../views/mitra/MpStore')
    },

    {
        path: '/product',
        name: 'Product',
        // lazy-loaded
        component: () => import('../views/product/ProductView')
    },
    {
        path: '/product-category',
        name: 'ProductCateg',
        // lazy-loaded
        component: () => import('../views/product/ProductCategView')
    },
    {
        path: '/product-sales-brand',
        name: 'ProductSalesBrand',
        // lazy-loaded
        component: () => import('../views/product/SalesBrandView')
    },
    {
        path: '/product-ar-payment',
        name: 'ProductTax',
        // lazy-loaded
        component: () => import('../views/product/ProductTaxView')
    },
    {
        path: '/warehouse',
        name: 'WarehouseView',
        // lazy-loaded
        component: () => import('../views/inventory/WarehouseView')
    },
    {
        path: '/saldo-stock',
        name: 'WarehouseView',
        // lazy-loaded
        component: () => import('../views/inventory/StockView')
    },
    {
        path: '/warning-stock',
        name: 'WarningStockView',
        // lazy-loaded
        component: () => import('../views/inventory/WarningStockView')
    },
    {
        path: '/umur-stock',
        name: 'UmurStockView',
        // lazy-loaded
        component: () => import('../views/inventory/UmurStockView')
    },

    {
        path: '/sales-invoice',
        name: 'SalesInvoice',
        component: () => import('../views/sales/SalesInvoiceView.vue')
    },

    {
        path: '/sales-invoice-type1',
        name: 'SalesInvoice',
        component: () => import('../views/sales/SalesInvoiceView')
    },
    {
        path: '/sales-invoice-type1/:statusPengiriman',
        name: 'SalesInvoice',
        component: () => import('../views/sales/SalesInvoiceView')
    },
    {
        path: '/sales-invoice-type1/:statusPengiriman/:dateFrom/:dateTo',
        name: 'SalesInvoice',
        component: () => import('../views/sales/SalesInvoiceView')
    },

    {
        path: '/sales-delivery',
        name: 'SalesDelivery',
        component: () => import('../views/sales/SalesDeliveryView')
    },
    {
        path: '/sales-return-management',
        name: 'SalesReturnManagement',
        component: () => import('../views/sales/ReturnManagementView')
    },
    {
        path: '/sales-print-resi',
        name: 'PrintResi',
        component: () => import('../views/sales/PrintResiView')
    },
    {
        path: '/sales-summary-product',
        name: 'SalesSummaryProductView',
        component: () => import('../views/sales/SalesSummaryProductView.vue')
    },
    {
        path: '/mobile-order-entry',
        name: 'MobileOrderEntry',
        component: () => import('../views/cloud-mobile/OrderEntryView.vue')
    },
    {
        path: '/pegawai',
        name: 'SalesmanView',
        component: () => import('../views/sales/SalesmanView.vue')
    },

    {
        path: '/pay-method',
        name: 'PayMethod',
        component: () => import('../views/sales/PayMethodView')
    },

    {
        path: '/pembayaran-ar-invoice',
        name: 'PaymentArInvoice',
        component: () => import('../views/piutang/PaymentArInvoice')
    },
    {
        path: '/pembayaran-ar',
        name: 'ArPaymentView',
        component: () => import('../views/piutang/ArPaymentView')
    },

    {
        path: '/setting-promosi',
        name: 'SettingPromosi',
        component: () => import('../views/promotions/SettingPromosi')
    },
    {
        path: '/report-komisi',
        name: 'ReportKomisi',
        component: () => import('../views/laporan/KomisiView')
    },
    {
        path: '/reports',
        name: 'Reports',
        component: () => import('../views/laporan/ReportsView')
    },
    {
        path: '/reports-analisis',
        name: 'AnalisisView',
        component: () => import('../views/laporan/AnalisisView')
    },
    {
        path: '/privacy',
        name: 'PrivacyView',
        component: () => import('../views/PrivacyView')
    }

]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})


// router.beforeEach((to, from, next) => {
//     const isAuthenticated = JSON.parse(localStorage.getItem("authenticated"))

//     // const isAuthenticated = this.$store.state.isAuthenticated
//     // console.log(store.getters['getIsAuthenticated'])
//     // const isAuthenticated = store.getters['isAuthenticated'];

//     if (to.name !== "Login" & !isAuthenticated) next({ name: "Login" })
//     if (to.name === "Login" & isAuthenticated) next({ name: "Home" })
//     else next()

//     console.log(`${to.name} => ${from.name} => ${next.name}`)
// })

let usingAuthLinks = ['Profile', 'setting']
router.beforeEach((to, from, next) =>{
    if (store.state.auth.user ===null){
        if (usingAuthLinks.includes(to.name)){
            next({name: 'Login'})
        }else {
            next()
        }
    }else {
        next()
    }

})
export default router