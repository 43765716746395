export default class FtSaleshFilter {
  constructor(
      id = 0,
      pageNo= 0,
      pageSize = 0,
      sortBy="id",
      order="DESC",
      search="",

      fdivisionIds =[],
      fsalesmanIds=[],
      fcustomerIds =[],
      fstoreIds =[],
      fexpedisiIds=[],
      statusPengirimanIds =[],
      fmaterialIds=[],
      orderDateFrom= new Date(),
      orderDateTo = new Date(),
      listRemark = [],
      fwarehouseIds = [],
      type = 0,

      invoiceDateFrom= new Date(),
      invoiceDateTo = new Date(),
      deliveryDateFrom= new Date(),
      deliveryDateTo = new Date(),

      tipeFakturIds =[],

      itemIds =[],
      trDateFrom= new Date(),
      trDateTo = new Date(),

  ) {
    this.id = id;
    this.pageNo = pageNo;
    this.pageSize = pageSize;
    this.sortBy = sortBy;
    this.order = order;
    this.search = search;

    this.fdivisionIds = fdivisionIds;
    this.fsalesmanIds = fsalesmanIds;
    this.fcustomerIds = fcustomerIds;
    this.fstoreIds = fstoreIds;
    this.fexpedisiIds = fexpedisiIds;
    this.statusPengirimanIds = statusPengirimanIds;
    this.fmaterialIds = fmaterialIds;
    this.orderDateFrom = orderDateFrom;
    this.orderDateTo = orderDateTo;
    this.listRemark = listRemark;
    this.fwarehouseIds = fwarehouseIds;

    this.type = type;

    this.invoiceDateFrom = invoiceDateFrom;
    this.invoiceDateTo = invoiceDateTo;
    this.deliveryDateFrom = deliveryDateFrom;
    this.deliveryDateTo = deliveryDateTo;

    this.tipeFakturIds = tipeFakturIds;

    this.itemIds = itemIds;
    this.trDateFrom = trDateFrom;
    this.trDateTo = trDateTo;

  }

}
