import axios from 'axios';
import authHeader from "../../services/auth-header";
import ConstApiUrls from "../../services/const-api-urls";

const API_URL = ConstApiUrls.API_SERVICE_URL

class FMaterialService {
    getApiUrl() {
        return API_URL
    }

    getAllFMaterial(){
        return axios.get(API_URL + `getAllFMaterial`, { headers: authHeader() });
    }
    getAllFMaterialContaining(page, pageSize, sortBy, order, search){
        // return axios.get(API_URL + `getAllFMaterial`, { headers: authHeaderMultipart() });
        return axios.get(API_URL + `getAllFMaterialContaining?page=${page}&pageSize=${pageSize}&sortBy=${sortBy}&order=${order}&search=${search}`, { headers: authHeader() });
    }
    getAllFMaterialContainingExt(fmaterialFilter){
        return axios.post(API_URL + `getAllFMaterialContainingExt`, fmaterialFilter,{ headers: authHeader() });
    }
    getFMaterialById(id){
        return axios.get(API_URL + `getFMaterialById/${id}`, { headers: authHeader() });
    }
    updateFMaterial(item){
        return axios.put(API_URL + `updateFMaterial/${item.id}`, item, {headers: authHeader()})
    }
    updateFMaterialAndFix(item){
        return axios.put(API_URL + `updateFMaterialAndFix/${item.id}`, item, {headers: authHeader()})
    }
    createFMaterial(item){
        return axios.post(API_URL + `createFMaterial`, item, {headers: authHeader()})
    }
    createFMaterialAndFix(item){
        return axios.post(API_URL + `createFMaterialAndFix`, item, {headers: authHeader()})
    }
    deleteFMaterial(id){
        return axios.delete(API_URL + `deleteFMaterial/${id}`, {
            headers: authHeader()
        });
    }
    deleteAllFMaterial(itemIds){
        // let user = JSON.parse(localStorage.getItem('user'));
        // return axios.delete(API_URL + `deleteAllFMaterial`, {
        //     headers:  authHeaderMultipart(),
        //     data: {
        //         message: 'delete  success'
        //     }
        // });
        return axios.delete(API_URL + `deleteAllFMaterial`, {
            headers:  authHeader(),
            data: itemIds
        });


    }

    updateMappingFMaterialList(itemList){
        // console.log(JSON.stringify(itemList))
        return axios.put(API_URL + `updateMappingFMaterialList`, itemList, {headers: authHeader()})
    }

}
export default new FMaterialService()