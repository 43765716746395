export default class FMaterial {
  constructor(
      id,
      pcode,
      barcode,
      pname,
      avatarImage,
      statusActive,
      branch,
      childOf,
      description,
      freeGood,
      fdivisionBean,
      ftaxBean,
      taxable,
      fvendorBean,
      fwarehouseBean,
      materialType,
      fdistributionChannelBean,
      fmaterialGroup3Bean,
      fmaterialSalesBrandBean,
      principalCode,
      uom1,
      uom2,
      uom3,
      uom4,
      convfact1,
      convfact2,
      convfact3,
      priceUom,
      pprice,
      ppriceAfterPpn,
      pprice2,
      pprice2AfterPpn,
      sprice,
      spriceAfterPpn,
      sprice2,
      sprice2AfterPpn,
      sprice2ToDist1,
      sprice2ToDist2,
      sprice2ToDist3,
      sprice2ToDistAfterPpn,
      weightSmalest,
      caseWeight
  ) {
    this.id = id;
    this.pcode = pcode;
    this.barcode = barcode;
    this.pname = pname;
    this.avatarImage = avatarImage;
    this.statusActive = statusActive;
    this.branch = branch;
    this.childOf = childOf;
    this.description = description;
    this.freeGood = freeGood;
    this.fdivisionBean = fdivisionBean;
    this.ftaxBean = ftaxBean;
    this.taxable = taxable;
    this.fvendorBean = fvendorBean;
    this.fwarehouseBean = fwarehouseBean;
    this.materialType = materialType;
    this.fdistributionChannelBean = fdistributionChannelBean;
    this.fmaterialGroup3Bean = fmaterialGroup3Bean;
    this.fmaterialSalesBrandBean = fmaterialSalesBrandBean;
    this.principalCode = principalCode;
    this.uom1 = uom1;
    this.uom2 = uom2;
    this.uom3 = uom3;
    this.uom4 = uom4;
    this.convfact1 = convfact1;
    this.convfact2 = convfact2;
    this.convfact3 = convfact3;
    this.priceUom = priceUom;
    this.pprice = pprice;
    this.ppriceAfterPpn = ppriceAfterPpn;
    this.pprice2 = pprice2;
    this.pprice2AfterPpn = pprice2AfterPpn;
    this.sprice = sprice;
    this.spriceAfterPpn = spriceAfterPpn;
    this.sprice2 = sprice2;
    this.sprice2AfterPpn = sprice2AfterPpn;
    this.sprice2ToDist1 = sprice2ToDist1;
    this.sprice2ToDist2 = sprice2ToDist2;
    this.sprice2ToDist3 = sprice2ToDist3;
    this.sprice2ToDistAfterPpn = sprice2ToDistAfterPpn;
    this.weightSmalest = weightSmalest;
    this.caseWeight = caseWeight;
  }
}
