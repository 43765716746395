export default class FMaterialMap {
  constructor(
      id,
      fmaterialBean,
      toQty,
      toMaterialBean,
      created,
      modified,
      modifiedBy
  ) {
    this.id = id;
    this.fmaterialBean = fmaterialBean;
    this.toQty = toQty;
    this.toMaterialBean = toMaterialBean;
    this.created = created;
    this.modified = modified;
    this.modifiedBy = modifiedBy;
  }
}