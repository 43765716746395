<template>
  <section id="trust" style="background-color: #9CB5A7 ">
      <div class="py-12"></div>
    <v-container class="text-center">
      <h2 class="white--text display-2 font-weight-bold mb-3">Telah dipercaya oleh Brand Ternama Dalam Negeri</h2>
      <v-row class="text-center">
        <v-col
            cols="12"
            sm="3"
            md="3">
            <v-img
                src="@/assets/images/3.png"
            ></v-img>
        </v-col>
        <v-col
            cols="12"
            sm="3"
            md="3">
          <v-img

              src="@/assets/images/4.png"
          ></v-img>
        </v-col>
        <v-col
            cols="12"
            sm="3"
            md="3">
          <v-img

              src="@/assets/images/5.png"
          ></v-img>
        </v-col>
        <v-col
            cols="12"
            sm="3"
            md="3">
          <v-img

              src="@/assets/images/6.png"
          ></v-img>
        </v-col>
      </v-row>
    </v-container>
      <div class="py-12"></div>
  </section>
</template>

<script>
export default {
  name: "Trust"
}
</script>

<style scoped>

</style>