const ConstApiUrls = ({

    // AUTH_SERVICE_URL: 'http://localhost:8081/api/auth/',
    // TEST_SERVICE_URL: 'http://localhost:8081/api/test/',
    // API_SERVICE_URL: 'http://localhost:8081/api/desgreen/'

    /**
     * DESGREEN
     */
    AUTH_SERVICE_URL: 'https://rest-landahur.des-green.com/api/auth/',
    TEST_SERVICE_URL: 'https://rest-landahur.des-green.com/api/test/',
    API_SERVICE_URL: 'https://rest-landahur.des-green.com/api/desgreen/'


    /**
     * KARANG4 PMK
     */
    // AUTH_SERVICE_URL: 'https://rest-karang4.des-green.org/api/auth/',
    // TEST_SERVICE_URL: 'https://rest-karang4.des-green.org/api/test/',
    // API_SERVICE_URL: 'https://rest-karang4.des-green.org/api/desgreen/'

    /**
     * SASTRO CONTABO VPS
     */
    // AUTH_SERVICE_URL: 'http://109.123.237.132:8787/api/auth/',
    // TEST_SERVICE_URL: 'http://109.123.237.132:8787/api/test/',
    // API_SERVICE_URL: 'http://109.123.237.132:8787/api/desgreen/'

    /**
     * LANDAHUR SERVER
     */
    // AUTH_SERVICE_URL: 'http://36.89.37.74:8787/api/auth/',
    // TEST_SERVICE_URL: 'http://36.89.37.74:8787/api/test/',
    // API_SERVICE_URL: 'http://36.89.37.74:8787/api/desgreen/'

    /**
     * TEST TRIAL
     * VPS DES
     */
    // AUTH_SERVICE_URL: 'http://des-green.com:8787/api/auth/',
    // TEST_SERVICE_URL: 'http://des-green.com:8787/api/test/',
    // API_SERVICE_URL: 'http://des-green.com:8787/api/desgreen/'

})
export default ConstApiUrls
