<template>
<!--  <v-footer class="pa-3 color-gradient-1" height="auto" inset app>-->
  <v-footer class="pa-3 color-gradient-2" height="auto">
<!--    <v-spacer></v-spacer>-->
<!--    <v-flex text-xs-center grey&#45;&#45;text xs12>-->
<!--      &copy; {{ new Date().getFullYear() }} — SASTRO DROPSHIP-->
<!--    </v-flex>-->

    <v-row class="ml-8">
      <v-col cols="12" sm="3" md="3">
        <div class="headline font-weight-black justify-center">
          <span class="orange--text text--darken-2">DES</span>
          <span class="green--text text--darken-2">GREEN</span>
<!--          <span><v-avatar size="30" class="blue darken-1 white&#45;&#45;text">ID</v-avatar></span>-->
        </div>
        <div class="subtitle-1 font-weight-bold">PT. DES GREEN NUSANTARA</div>
        <div class="caption">Dsn Wangkal, Desa Tengger Lor, Kec Kunjang, Kab Kediri, Jawa Timur</div>
        <div class="caption">+62 821 43574 692</div>
      </v-col>
      <v-col cols="12" sm="3" md="3">
        <div class="subtitle-1 font-weight-bold blue--text justify-center">Produk</div>
        <div class="caption">DES Green</div>
        <div class="caption">ERP & CRM</div>
        <div class="caption">Aplikasi Pemerintahan</div>
        <div class="caption">Konsultan IT, Bisnis, Akuntasi, Pajak</div>
      </v-col>
      <v-col cols="12" sm="3" md="3">
        <div class="subtitle-1 font-weight-bold blue--text justify-center">Kebijakan Perusahaan</div>
        <div class="caption">privasi & Keamanan Data</div>
        <div class="caption">Syarat & Ketentuan</div>

        <div class="mt-2">
          <div class="icons">
            <a href="#" class="icon icon--instagram">
              <v-icon>mdi-instagram</v-icon>
            </a>
            <a href="#" class="icon icon--twitter">
              <v-icon>mdi-twitter</v-icon>
            </a>
            <a href="#" class="icon icon-tiktok">
              <v-icon>mdi-music</v-icon>
            </a>
            <a href="#" class="icon icon--github">
              <v-icon>mdi-github</v-icon>
            </a>
          </div>

        </div>
      </v-col>

    </v-row>
  </v-footer>
</template>
<script>
export default {
  name: "AppFooter"
};
</script>

<style scoped>

  .icons {
    display: flex;
    column-gap: 25px;
  }

  .icon {
    width: 50px;
    height: 50px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    font-size: 26px;
    color: #fff;
    border-radius: 50%;
    outline: 2px solid #fff;
    transition-property:
        outline-offset, outline-color,
        background-color;
    transition-duration: .25s;
  }

  .icon:hover {
    outline-offset: 4px;
  }

  .icon:hover i {
    animation: shake .25s;
  }

  .icon--instagram:hover {
    background-image: radial-gradient(circle at 30% 107%,
    #fdf497 0%, #fdf497 5%,
    #fd5949 45%, #d6249f 60%,
    #285AEB 90%);
    outline-color: #a02d76;
  }

  .icon--twitter:hover {
    background-color: #1da1f2;
    outline-color: #1da1f2;
  }

  .icon-tiktok:hover {
    background-image: radial-gradient(circle at 30% 107%,
    #9afd97 0%, #97fd99 5%,
    #fd5949 45%, #d6249f 60%,
    #35eb28 90%);
    outline-color: #a02d76;
  }

  .icon--github:hover {
    background-color: #2ea44f;
    outline-color: #2ea44f;
  }

  @keyframes shake {
    10% {
      transform: rotate(15deg);
    }

    20% {
      transform: rotate(-15deg);
    }

    30% {
      transform: rotate(15deg);
    }

    40% {
      transform: rotate(-15deg);
    }
  }

</style>
